<template>
  <div>
    <app-header :title="__('common.privacyPolicy')"/>
    <div class="max-width-page">
      <div class="layout layout--wrap">
        <div class="flex xs12">
          <div class="tile bodytext">
            <div class="credits">
              <h1>Credits</h1>
              <p>A project like this, can't be done by a single person alone. So we want to thank all the people that put there sweat, love, time, effort, their soul into this project!</p>
            </div>
          </div>
        </div>

        <div class="flex xs12 sm6 mt">
          <div class="tile bodytext">
            <div class="credits">
              <h1>Adivo</h1>
              <ul class="futuristic">
                <li>Alpha 2 items</li>
                <li>Alpha 2 recipes</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="flex xs12 sm6 mt">
          <div class="tile bodytext">
            <div class="credits">
              <h1>Ardescar</h1>
              <ul class="futuristic">
                <li>Alpha 3 recipes</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="flex xs12 sm6 mt">
          <div class="tile bodytext">
            <div class="credits">
              <h1>b3sso</h1>
              <ul class="futuristic">
                <li>Setup and configuring of the forum</li>
                <li>Constructive design critics</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="flex xs12 sm6 mt">
          <div class="tile bodytext">
            <div class="credits">
              <h1>meigrafd</h1>
              <ul class="futuristic">
                <li>Alpha 3 item images</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="flex xs12 sm6 mt">
          <div class="tile bodytext">
            <div class="credits">
              <h1>Oije</h1>
              <ul class="futuristic">
                <li>Alpha 2 items</li>
                <li>Alpha 2 recipes</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="flex xs12 sm6 mt">
          <div class="tile bodytext">
            <div class="credits">
              <h1>Skeletmaster'</h1>
              <ul class="futuristic">
                <li>Configuring of the forum</li>
                <li>Skill Tree Data</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="flex xs12 sm6 mt">
          <div class="tile bodytext">
            <div class="credits">
              <h1>Underhand Aerial</h1>
              <ul class="futuristic">
                <li>Skill Tree Data</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="flex xs12 sm6 mt">
          <div class="tile bodytext">
            <div class="credits">
              <h1>WildChild85</h1>
              <ul class="futuristic">
                <li>Coding the API, UI</li>
                <li>Server Administration</li>
                <li>Installing and configuring of the Git-Software</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="flex xs12 sm6 mt">
          <div class="tile bodytext">
            <div class="credits">
              <h1>z0KNg</h1>
              <ul class="futuristic">
                <li>Coding the API</li>
                <li>Coding the Discord bot</li>
                <li>Alpha 3 items</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="flex xs12 sm6 mt">
          <div class="tile bodytext">
            <div class="credits">
              <h1>ZarTaen</h1>
              <ul class="futuristic">
                <li>Server Security</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { localizationMixin } from '../../mixins/localization';
  import { pageTitleMixin } from "../../mixins/pageTitle";
  import AppHeader from "../../components/AppHeader";

  export default {
    mixins: [localizationMixin, pageTitleMixin],
    components: { AppHeader },
    data: () => ({
      language: 'en',
      languages: [
        { value: 'en', title: 'english' },
        { value: 'de', title: 'deutsch' }
      ]
    }),
    methods: {},
    created() {
      if(navigator.language.substr(0,2).toLocaleLowerCase() === 'de') {
        this.language = 'de';
        this.$forceUpdate();
      }
      this.setPageTitle(this.__('common.privacyPolicy'));
    }
  }
</script>
